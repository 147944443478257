// @mui material components
import { Grid } from "@mui/material";

//  components
import VuiTypography from "components/VuiTypography";
import VuiCard from "components/VuiCard";


// Data
import React from 'react';


function PreferencesWelcome({userCanEdit}) {
  return (
    <Grid container>
        <Grid item xs={12} lg={8}>
            <VuiCard sx={{
                marginBottom:5,
            }}>
            <Grid container justifyContent='left'>
                <Grid item> 
                <VuiTypography variant="lg" fontWeight="bold"> 
                    Welcome to the Universal Notifier Preferences Page!
                </VuiTypography>
                <VuiTypography variant='h6' fontWeight='light'>
                    Below you will see the workloads or clouds that you are assigned to. From this page you can enable a workload or cloud
                    to use the Universal Notifier, or you can change your Universal Notifier preferences for a workload or cloud
                    that has the Universal Notifier enabled.
                </VuiTypography>
                {userCanEdit ? (
                <div>
                    {<br/>}
                    <VuiTypography variant="lg" fontWeight="bold"> 
                        How To Use the Universal Notifier Preferences Page:
                    </VuiTypography>
                    <VuiTypography variant='h6' fontWeight='light'>
                        - To enable a workload or cloud simply flip the switch on the right side for any workload below and accept the prompt. {<br/>}
                        - To change the severities, select 1 or more workloads/clouds using the checkbox on the left hand side and then select a severity level 
                        in the dropdown box to apply to those workloads.
                        (Note: The workload/cloud you want to select must be enabled before you can select it.)
                    </VuiTypography>
                </div>
                ) : (
                <VuiTypography variant='lg' fontWeight='bold' isError={true}>
                    <br/>
                    You do not have permissions to edit Universal Notifier Preferences. Please contact the Product Security Engineering team if you believe this is incorrect.
                </VuiTypography>
                )}
                </Grid> 
            </Grid>
            </VuiCard> 
        </Grid>
    </Grid>
  )
}

export default PreferencesWelcome